import { defineMessages } from 'react-intl-next';

export default defineMessages({
	rovoChatButtonTextChat: {
		id: 'highlight-actions.rovo-chat.button-text',
		defaultMessage: 'Chat',
		description: 'Text for button that opens the Rovo Chat sidebar',
	},
	rovoChatButtonTextAskInChat: {
		id: 'highlight-actions.rovo-chat.button-text-ask-in-chat',
		defaultMessage: 'Ask in chat',
		description: 'Text for button that opens the Rovo Chat sidebar',
	},
	rovoChatOpenChatTooltip: {
		id: 'highlight-actions.rovo-chat-open-chat.tooltip',
		defaultMessage: 'Open Chat',
		description: 'Tooltip for button that opens the Rovo Chat sidebar',
	},
	rovoChatMoreOptionsTooltip: {
		id: 'highlight-actions.rovo-chat-more-options.tooltip',
		defaultMessage: 'Chat recommendations',
		description: 'Tooltip label for the dropdown menu to show more options',
	},
	rovoChatSummarizeOptionText: {
		id: 'highlight-actions.rovo-chat-summarize-option.text',
		defaultMessage: 'Summarize',
		description: 'Text for the "Summarize" option in the Rovo Chat more options popup',
	},
	rovoChatExplainOptionText: {
		id: 'highlight-actions.rovo-chat-explain-option.text',
		defaultMessage: 'Explain',
		description: 'Text for the "Explain" option in the Rovo Chat more options popup',
	},
	rovoChatFindResourcesOptionText: {
		id: 'highlight-actions.rovo-chat-find-resources-option.text',
		defaultMessage: 'Find resources',
		description: 'Text for the "Find resources" option in the Rovo Chat more options popup',
	},
	rovoChatGiveFeedbackOptionText: {
		id: 'highlight-actions.rovo-chat-give-feedback-option.text',
		defaultMessage: 'Give feedback',
		description: 'Text for the "Give feedback" option in the Rovo Chat more options popup',
	},
	rovoChatGiveExamplesOptionText: {
		id: 'highlight-actions.rovo-chat-give-examples-option.text',
		defaultMessage: 'Give examples',
		description: 'Text for the "Give examples" option in the Rovo Chat more options popup',
	},
	rovoChatSummarizePrompt: {
		id: 'highlight-actions.rovo-chat-summarize-prompt',
		defaultMessage: `Highlight the key points from this. Focus on essential information and exclude unnecessary details. Provide a concise overview in a single paragraph.`,
		description: 'Prompt for the "Summarize" option in the Rovo Chat more options popup',
	},
	rovoChatExplainPrompt: {
		id: 'highlight-actions.rovo-chat-explain-prompt',
		defaultMessage:
			'Explain this by rephrasing it and adding additional detail, but use very simple language, short sentences, and provide examples or definition to any technical terminology use in the original phrasing.',
		description: 'Prompt for the "Explain" option in the Rovo Chat more options popup',
	},
	rovoChatFindResourcesPrompt: {
		id: 'highlight-actions.rovo-chat-find-resources-prompt',
		defaultMessage:
			'Find resources within organizational knowledge only that supports the statements made.',
		description: 'Prompt for the "Find resources" option in the Rovo Chat more options popup',
	},
	rovoChatGiveFeedbackPrompt: {
		id: 'highlight-actions.rovo-chat-give-feedback-prompt',
		defaultMessage:
			'Give feedback by reviewing the phrasing of this. Provide commentary on word choice, grammar, punctuation. Be positive and enthusiastic but always provide at least one thing to improve or change.',
		description: 'Prompt for the "Give feedback" option in the Rovo Chat more options popup',
	},
	rovoChatGiveExamplesPrompt: {
		id: 'highlight-actions.rovo-chat-give-examples-prompt',
		defaultMessage:
			'Give examples of this from organizational resources or public knowledge. If there are no resources to provide attempt to give a metaphorical example that may help someone understand this more.',
		description: 'Prompt for the "Give examples" option in the Rovo Chat more options popup',
	},
	rovoChatCustomPromptPlaceholder: {
		id: 'highlight-actions.rovo-chat-custom-prompt.placeholder',
		defaultMessage: 'Ask AI about this',
		description: 'Placeholder text for the custom prompt input in the Rovo Chat more options popup',
	},
	rovoChatCustomPromptSubmitButtonText: {
		id: 'highlight-actions.rovo-chat-custom-prompt-submit-button.text',
		defaultMessage: 'Send',
		description: 'Text for the submit button in the Rovo Chat custom prompt input',
	},
});

import { FabricChannel } from '@atlaskit/analytics-listeners';

export const ANALYTICS_CHANNEL = FabricChannel.aiMate;

export type InteractionSource =
	| 'chatInput'
	| 'embeddedAgentConversationStarters'
	| 'emptyStateSuggestions'
	| 'followUps'
	| 'movedOver'
	| 'ntpSuggestion'
	| 'profileCardConversationStarters'
	| 'rightClickDefine'
	| 'rightClickSummarize'
	| 'urlParam'
	| 'viewAgentConversationStarters'
	| 'resendMessage';

// Which context a follow up was triggered from
export type FollowUpSource =
	| 'ai-mate'
	| 'extension'
	| 'none'
	| 'page-summary'
	| 'reading-aids'
	| 'sain';

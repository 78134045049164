/** @jsx jsx */
import { useState, useCallback, useMemo, useContext } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';
import { styled, css, jsx } from '@compiled/react';

import Button from '@atlaskit/button/custom-theme-button';
import { Flex } from '@atlaskit/primitives';
import ChevronDownIcon from '@atlaskit/icon/utility/chevron-down';
import AppIcon from '@atlaskit/icon/core/app';
import AppsIcon from '@atlaskit/icon/core/apps';
import { token } from '@atlaskit/tokens';
import Popup from '@atlaskit/popup';
import Tooltip from '@atlaskit/tooltip';
import Image from '@atlaskit/image';

import {
	VIEW_PAGE_CONTEXT_MENU_FORGE_EXPERIENCE,
	ExperienceTrackerContext,
} from '@confluence/experience-tracker';
import {
	extensionTitle,
	ForgeKeyboardShortcut,
	ForgeKeyboardShortcutVisualizer,
} from '@confluence/forge-ui';
import type { ForgeUIExtensionType } from '@confluence/forge-ui';
import {
	openContextMenuClick,
	simulateItemClick,
	simulateItemMouseOver,
} from '@confluence/legacy-keyboard-shortcut-helpers/entry-points/legacyKeyboardShortcutHelpers';

const i18n = defineMessages({
	selectApp: {
		id: 'highlight-actions.select.app',
		defaultMessage: 'Select app',
		description: 'Tooltip label for the dropdown menu to show other apps',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PopupPaddingContainer = styled.div({
	padding: `${token('space.200')} ${token('space.300')}`,
});

const dropdownComponentListStyle = css({
	display: 'flex',
	flexDirection: 'column',
	paddingTop: token('space.100'),
	paddingBottom: token('space.100'),
	// The Inline dialog has build-in padding, next margin will void it
	margin: `${token('space.negative.200')} ${token('space.negative.300')}`,
	overflowY: 'auto',
	maxHeight: '300px',
	maxWidth: '300px',
});

const iconImageStyle = css({
	height: '24px',
	width: '24px',
});

const noIconWrapperStyle = css({
	width: '24px',
	height: '24px',
	borderRadius: '50%',
	backgroundColor: token('color.background.disabled'),
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-around',
});

type OtherActionsProps = {
	connectItems: any[];
	forgeItems?: ReadonlyArray<ForgeUIExtensionType>;
	selectForgeExtension?: (extension: ForgeUIExtensionType) => void;
};

export const OtherActions = ({
	connectItems,
	forgeItems = [],
	selectForgeExtension = () => {},
}: OtherActionsProps) => {
	const intl = useIntl();
	const [isOpen, setIsOpen] = useState(false);
	const experienceTracker = useContext(ExperienceTrackerContext);

	const onClose = useCallback(() => {
		setIsOpen(false);
	}, [setIsOpen]);

	const connectComponents = useMemo(
		() =>
			connectItems.map((item) => {
				return (
					<Button
						iconBefore={
							item?.icon?.path ? (
								<Image css={iconImageStyle} src={item.icon.path} />
							) : (
								<div css={noIconWrapperStyle}>
									<AppIcon label="No icon" color={token('color.icon.disabled')} />
								</div>
							)
						}
						key={item.completeKey}
						appearance="subtle"
						data-key={item.completeKey}
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
						className={item.styleClass}
						href={item.url}
						style={{
							// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
							textAlign: 'left', // we can't wrap Button with `styled` as inner styles override this
							// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
							borderRadius: 0,
						}}
						shouldFitContainer
						title={item.label}
					>
						{item.label}
					</Button>
				);
			}),
		[connectItems],
	);

	const forgeComponents = useMemo(
		() =>
			forgeItems.map((item) => {
				const appTitle = extensionTitle(item);

				return (
					<ForgeKeyboardShortcutVisualizer key={item.id} module={item}>
						<Button
							key={item.id}
							id={item.id}
							appearance="subtle"
							style={{
								// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
								textAlign: 'left', // we can't wrap Button with `styled` as inner styles override this

								paddingLeft: connectItems.length ? '40px' : '8px',
								// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
								borderRadius: 0,
							}}
							onClick={() => {
								experienceTracker.start({
									name: VIEW_PAGE_CONTEXT_MENU_FORGE_EXPERIENCE,
									id: item.id,
									timeout: 10000,
								});
								selectForgeExtension(item);
							}}
							shouldFitContainer
							title={appTitle}
						>
							{appTitle}
						</Button>
					</ForgeKeyboardShortcutVisualizer>
				);
			}),
		[connectItems, forgeItems, selectForgeExtension, experienceTracker],
	);

	const forgeKeyboardShortcuts = useMemo(
		() =>
			forgeItems.map((module) => (
				<ForgeKeyboardShortcut
					key={module.id}
					module={module}
					action={() => {
						openContextMenuClick();
						simulateItemClick(module.id);
						simulateItemMouseOver(module.id);
					}}
				/>
			)),
		[forgeItems],
	);

	const dropDownComponents = useMemo(
		() => (
			<div css={dropdownComponentListStyle}>
				{connectComponents}
				{forgeComponents}
			</div>
		),
		[connectComponents, forgeComponents],
	);

	if (!connectItems.length && !forgeItems.length) {
		return null;
	}

	return (
		<Popup
			placement="bottom-start"
			isOpen={isOpen}
			onClose={onClose}
			autoFocus={false} // keep this param as FF unselect the text after the trigger button is pressed
			content={() => <PopupPaddingContainer>{dropDownComponents}</PopupPaddingContainer>}
			trigger={(triggerProps) => (
				<div {...triggerProps}>
					<Tooltip content={intl.formatMessage(i18n.selectApp)}>
						{(tooltipProps) => (
							<Button
								{...tooltipProps}
								id="context-menu-other-apps"
								aria-expanded={isOpen}
								appearance="subtle"
								isSelected={isOpen}
								onClick={() => setIsOpen(!isOpen)}
								iconBefore={
									<Flex alignItems="center" gap="space.050">
										<AppsIcon label="" />
										<ChevronDownIcon label="Drop down" />
									</Flex>
								}
								style={{
									// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
									margin: token('space.025'),
									// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
									marginLeft: token('space.negative.025'),
								}}
								testId="context-menu-other-apps"
							/>
						)}
					</Tooltip>
					{forgeKeyboardShortcuts}
				</div>
			)}
		/>
	);
};

/**
 * Formats the prompt by adding the selected text as inline code followed by the prompt below.
 * We handle the selected text formatting here rather than in formatMessage to preserve the original text.
 * This is particularly important for features like translation, where we want to maintain the source text exactly as selected.
 */
export const formatPromptForSelectedText = ({
	selectedText,
	prompt,
}: {
	selectedText: string;
	prompt: string;
}): string => {
	return `\`${selectedText}\`\n\n${prompt.replace(`\`{selectedText}\``, '')}`.trim();
};

/**
 * @jsxRuntime classic
 * @jsx jsx
 */

import { useCallback } from 'react';

import { useIntl } from 'react-intl-next';
import { di } from 'react-magnetic-di';

import Button, { IconButton } from '@atlaskit/button/new';
import { cssMap, jsx } from '@atlaskit/css';
import FeatureGates from '@atlaskit/feature-gate-js-client';
import Form, { Field, type FormApi } from '@atlaskit/form';
import SendIcon from '@atlaskit/icon/core/send';
import ChevronDownIcon from '@atlaskit/icon/utility/chevron-down';
import { RovoIcon } from '@atlaskit/logo';
import Popup from '@atlaskit/popup';
import { Box, Inline, Stack, Text } from '@atlaskit/primitives/compiled';
import { ConversationStarterPill } from '@atlaskit/rovo-agent-components';
import { usePublish } from '@atlaskit/rovo-triggers';
import Textfield from '@atlaskit/textfield';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { useAnalytics } from '@atlassian/conversation-assistant-instrumentation';

import {
	useRovoChatHighlightActions,
	useRovoChatHighlightActionsActions,
} from '../../controllers/highlight-actions';

import messages from './messages';
import { formatPromptForSelectedText } from './utils';

const styles = cssMap({
	popupContainer: {
		paddingTop: token('space.300'),
		paddingRight: token('space.300'),
		paddingBottom: token('space.300'),
		paddingLeft: token('space.300'),
	},
	// The form component in jira and the field component in confluence both have a default marginBlockStart which we need to override
	formWrapper: {
		marginBlockStart: token('space.negative.100'),
	},
	sendIconWrapper: {
		paddingInlineEnd: token('space.150'),
	},
	// TODO: https://product-fabric.atlassian.net/browse/ROVOFE-699 - Migrate other buttons in Confluence highlight actions menu to use new button instead of old to align with our button
	// The other items that live in the highlight actions menu in Confluence are using the old button component
	// so we need to offset the icon button to the left to align with the other items.
	// This is a temporary solution until we migrate the other buttons to the new button component.
	rovoChatMoreOptionsButtonContainer: {
		marginInlineStart: token('space.negative.100'),
	},
});

interface FormValues {
	prompt: string;
}

interface RovoChatActionsProps {
	selectedText: string;
	product: string;
	onClickChat: () => void;
}

type RovoButtonTextExperimentValues =
	| 'control'
	| 'not-enrolled'
	| 'rovo-chat-button-text-ask-in-chat';

export const RovoChatHighlightActions = ({
	selectedText,
	product,
	onClickChat,
}: RovoChatActionsProps) => {
	di(FeatureGates, useRovoChatHighlightActions);
	const { sendAnalyticsEvent } = useAnalytics();
	const { formatMessage } = useIntl();
	const publish = usePublish('ai-mate');
	const { setIsMoreOptionsPopupOpen } = useRovoChatHighlightActionsActions();
	const [{ isMoreOptionsPopupOpen }] = useRovoChatHighlightActions();
	const onClose = useCallback(() => {
		setIsMoreOptionsPopupOpen(false);
	}, [setIsMoreOptionsPopupOpen]);

	const handleClickChat = useCallback(() => {
		onClickChat();
		sendAnalyticsEvent({
			action: 'clicked',
			actionSubjectId: 'highlightActionsMenuRovoChatButton',
			attributes: {
				product,
			},
		});
	}, [onClickChat, sendAnalyticsEvent, product]);

	const handlePromptSubmit = useCallback(
		(values: FormValues, form: FormApi<FormValues>) => {
			publish({
				type: 'chat-new',
				source: `${product}-context-menu`,
				data: {
					name: values.prompt.substring(0, 30),
					dialogues: [],
					prompt: values.prompt,
				},
			});
			form.reset();
			sendAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'highlightActionsMenuSubmitPromptButton',
				attributes: {
					product,
				},
			});
		},
		[publish, sendAnalyticsEvent, product],
	);

	const rovoChatPromptOptions = [
		{
			id: 'context-menu-rovo-chat-summarize-option',
			actionType: 'summarize',
			prompt: formatMessage(messages.rovoChatSummarizePrompt),
			optionText: formatMessage(messages.rovoChatSummarizeOptionText),
		},
		{
			id: 'context-menu-rovo-chat-explain-option',
			actionType: 'explain',
			prompt: formatMessage(messages.rovoChatExplainPrompt),
			optionText: formatMessage(messages.rovoChatExplainOptionText),
		},
		{
			id: 'context-menu-rovo-chat-find-resources-option',
			actionType: 'find-resources',
			prompt: formatMessage(messages.rovoChatFindResourcesPrompt),
			optionText: formatMessage(messages.rovoChatFindResourcesOptionText),
		},
		{
			id: 'context-menu-rovo-chat-give-feedback-option',
			actionType: 'give-feedback',
			prompt: formatMessage(messages.rovoChatGiveFeedbackPrompt),
			optionText: formatMessage(messages.rovoChatGiveFeedbackOptionText),
		},
		{
			id: 'context-menu-rovo-chat-give-examples-option',
			actionType: 'give-examples',
			prompt: formatMessage(messages.rovoChatGiveExamplesPrompt),
			optionText: formatMessage(messages.rovoChatGiveExamplesOptionText),
		},
	];

	const renderPopupContent = () => {
		return (
			<Stack testId="context-menu-rovo-chat-popup" xcss={styles.popupContainer} space="space.150">
				{rovoChatPromptOptions.map((option) => {
					return (
						<ConversationStarterPill
							key={option.id}
							testId={option.id}
							onClick={() => {
								publish({
									type: 'chat-new',
									source: `${product}-context-menu`,
									data: {
										name: selectedText.substring(0, 30),
										dialogues: [],
										prompt: formatPromptForSelectedText({ selectedText, prompt: option.prompt }),
									},
								});
								sendAnalyticsEvent({
									action: 'clicked',
									actionSubject: 'button',
									actionSubjectId: 'highlightActionsMenuConvoStarterButton',
									attributes: {
										actionType: option.actionType,
										product,
									},
								});
							}}
						>
							{option.optionText}
						</ConversationStarterPill>
					);
				})}

				<Form onSubmit={handlePromptSubmit}>
					{({ formProps }) => (
						<Box xcss={styles.formWrapper}>
							<form {...formProps}>
								<Field name="prompt">
									{({ fieldProps: { value, ...fieldPropsRest }, meta: { dirty } }) => (
										<Textfield
											// this prevents the highlight actions popup from closing when clicking on the input
											onClick={(e) => e.stopPropagation()}
											{...fieldPropsRest}
											testId="context-menu-rovo-chat-custom-prompt"
											id="context-menu-rovo-chat-custom-prompt"
											autoComplete="off"
											elemAfterInput={
												<Box xcss={styles.sendIconWrapper}>
													<IconButton
														testId="context-menu-rovo-chat-custom-prompt-submit"
														isDisabled={!dirty || !value}
														label={formatMessage(messages.rovoChatCustomPromptSubmitButtonText)}
														type="submit"
														icon={SendIcon}
														spacing="compact"
													/>
												</Box>
											}
											placeholder={formatMessage(messages.rovoChatCustomPromptPlaceholder)}
											aria-label={formatMessage(messages.rovoChatCustomPromptPlaceholder)}
										/>
									)}
								</Field>
							</form>
						</Box>
					)}
				</Form>
			</Stack>
		);
	};

	const getButtonText = () => {
		const experimentCohort = FeatureGates.getExperimentValue<RovoButtonTextExperimentValues>(
			'rovo_chat_contextual_menu_button_text_experiment',
			'rovo-chat-button-text',
			'not-enrolled',
		);

		switch (experimentCohort) {
			case 'control':
				return formatMessage(messages.rovoChatButtonTextChat);
			case 'not-enrolled':
				return formatMessage(messages.rovoChatButtonTextChat);
			case 'rovo-chat-button-text-ask-in-chat':
				return formatMessage(messages.rovoChatButtonTextAskInChat);
			default:
				return formatMessage(messages.rovoChatButtonTextChat);
		}
	};

	return (
		<Inline>
			<Tooltip content={formatMessage(messages.rovoChatOpenChatTooltip)}>
				<Button
					appearance="subtle"
					iconBefore={() => <RovoIcon size="small" />}
					onClick={handleClickChat}
					testId="context-menu-rovo-chat-button"
				>
					<Text weight="medium">{getButtonText()}</Text>
				</Button>
			</Tooltip>

			<Popup
				placement="bottom-start"
				isOpen={isMoreOptionsPopupOpen}
				onClose={onClose}
				content={renderPopupContent}
				trigger={(triggerProps) => (
					<Box xcss={styles.rovoChatMoreOptionsButtonContainer}>
						<IconButton
							{...triggerProps}
							appearance="subtle"
							id="context-menu-rovo-chat-more-options"
							testId="context-menu-rovo-chat-more-options"
							isTooltipDisabled={false}
							icon={(props) => (
								<ChevronDownIcon
									{...props}
									label={formatMessage(messages.rovoChatMoreOptionsTooltip)}
								/>
							)}
							label={formatMessage(messages.rovoChatMoreOptionsTooltip)}
							onClick={() => {
								setIsMoreOptionsPopupOpen(!isMoreOptionsPopupOpen);
								sendAnalyticsEvent({
									action: 'clicked',
									actionSubject: 'button',
									actionSubjectId: 'highlightActionsMenuRovoChatMoreOptionsButton',
									attributes: {
										product,
									},
								});
							}}
						/>
					</Box>
				)}
			/>
		</Inline>
	);
};

import React, { createContext, useContext, useState, useMemo } from 'react';
import type { FC, ReactElement } from 'react';

import type { DocNode } from '@atlaskit/adf-schema';

import type { CommentHighlight } from './commentContextTypes';

export type ReattachComment = {
	id: string;
	inlineMarkerRef: string | null;
	author: CommentAuthor;
	adf: DocNode;
};

export type CommentAuthor = {
	displayName: string | null;
	accountId: string | null;
	profilePicture: {
		path: string;
	} | null;
};

export type ReattachCommentContextType = {
	commentToReattach: ReattachComment | null;
	reattachHighlight: CommentHighlight | null;
	isInReattachMode: () => boolean;
};

export type ReattachCommentDispatchContextType = {
	setCommentToReattach: (comment: ReattachComment | null) => void;
	setReattachHighlight: (highlight: CommentHighlight | null) => void;
	clearReattachMode: () => void;
};

export const ReattachCommentContext = createContext<ReattachCommentContextType>({
	commentToReattach: null,
	reattachHighlight: null,
	isInReattachMode: () => false,
});
ReattachCommentContext.displayName = 'ReattachCommentContext';

export const ReattachCommentDispatchContext = createContext<ReattachCommentDispatchContextType>({
	setCommentToReattach: () => {},
	setReattachHighlight: () => {},
	clearReattachMode: () => {},
});
ReattachCommentContext.displayName = 'ReattachCommentDispatchContext';

type ReattachCommentProviderProps = {
	children: ReactElement;
};

export const ReattachCommentProvider: FC<ReattachCommentProviderProps> = ({ children }) => {
	const [commentToReattach, setCommentToReattach] = useState<ReattachComment | null>(null);
	const [reattachHighlight, setReattachHighlight] = useState<CommentHighlight | null>(null);

	const providerValue = useMemo(
		() => ({
			commentToReattach,
			reattachHighlight,
			isInReattachMode: () => Boolean(commentToReattach),
		}),
		[commentToReattach, reattachHighlight],
	);

	const dispatchProviderValue = useMemo(
		() => ({
			setCommentToReattach,
			setReattachHighlight: (highlight: CommentHighlight | null) => {
				setReattachHighlight(highlight?.numMatches !== 0 ? highlight : null);
			},
			clearReattachMode: () => {
				setCommentToReattach(null);
				setReattachHighlight(null);
			},
		}),
		[setCommentToReattach, setReattachHighlight],
	);

	return (
		<ReattachCommentContext.Provider value={providerValue}>
			<ReattachCommentDispatchContext.Provider value={dispatchProviderValue}>
				{children}
			</ReattachCommentDispatchContext.Provider>
		</ReattachCommentContext.Provider>
	);
};

export const useReattachComment = () => {
	return useContext(ReattachCommentContext);
};

export const useReattachCommentDispatch = () => {
	return useContext(ReattachCommentDispatchContext);
};

import { type Action, createActionsHook, createHook, createStore } from 'react-sweet-state';

type RovoChatHighlightActionsState = {
	isMoreOptionsPopupOpen: boolean;
};

type RovoChatHighlightActionsAction = Action<RovoChatHighlightActionsState>;
type RovoChatHighlightActionsActions = {
	setIsMoreOptionsPopupOpen: (isOpen: boolean) => RovoChatHighlightActionsAction;
};

const store = createStore<RovoChatHighlightActionsState, RovoChatHighlightActionsActions>({
	name: 'RovoChatHighlightActionsStore',
	initialState: {
		isMoreOptionsPopupOpen: false,
	},
	actions: {
		setIsMoreOptionsPopupOpen:
			(isOpen: boolean) =>
			({ setState }) => {
				setState({
					isMoreOptionsPopupOpen: isOpen,
				});
			},
	},
});

export const useRovoChatHighlightActions = createHook(store);
export const useRovoChatHighlightActionsActions = createActionsHook(store);

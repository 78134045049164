import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { useChoreographerAssets } from '@confluence/choreographer-services';

export const READING_AIDS_CHANGEBOARDING_SPOTLIGHT_ID = 'reading-aids-spotlight';

const i18n = defineMessages({
	changeboardingSpotlightGotItButton: {
		id: 'contextual-reading-aids.changeboarding-spotlight.got-it-button',
		defaultMessage: 'Got it',
		description: 'The text for the button to dismiss the changeboarding spotlight',
	},
	changeboardingSpotlightText: {
		id: 'contextual-reading-aids.changeboarding-spotlight.text',
		defaultMessage:
			'Remember, this feature works best when you select specific terms, acronyms, or internal project/team names.',
		description: 'The text for the changeboarding spotlight',
	},
});

type ReadingAidsChangeboardingSpotlightProps = {
	contentId: string;
	onGotItClick: () => void;
};

export const ReadingAidsChangeboardingSpotlight = ({
	contentId,
	onGotItClick,
}: ReadingAidsChangeboardingSpotlightProps) => {
	const intl = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const handleOnClick = () => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				source: 'readingAidsSpotlightChangeboarding',
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'readingAidsSpotlightGotItButton',
				attributes: {
					contentId,
				},
			},
		});
		onGotItClick();
	};

	const { Spotlight } = useChoreographerAssets();

	return (
		<Spotlight
			actions={[
				{
					text: intl.formatMessage(i18n.changeboardingSpotlightGotItButton),
					onClick: handleOnClick,
				},
			]}
			target={READING_AIDS_CHANGEBOARDING_SPOTLIGHT_ID}
			targetRadius={4}
		>
			{intl.formatMessage(i18n.changeboardingSpotlightText)}
		</Spotlight>
	);
};

// want color of icon to be the same for regular/light/dark modes
import React from 'react';

import { B200, B400 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

const lightBlueColor = B200;
const blueColor = B400;
const greyColor = token('color.icon.disabled');

export const AtlassianIntelligenceIcon = ({
	greyed = false,
	size = 'small',
}: {
	greyed?: boolean;
	size?: 'smaller' | 'small' | 'large';
}) => (
	<svg
		width={size === 'smaller' ? '16' : size === 'small' ? '18' : '24'}
		height={size === 'smaller' ? '16' : size === 'small' ? '18' : '24'}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M17.1699 12C17.1699 11.4477 17.6176 11 18.1699 11H19.9999C20.5522 11 20.9999 11.4477 20.9999 12C20.9999 12.5523 20.5522 13 19.9999 13H18.1699C17.6176 13 17.1699 12.5523 17.1699 12Z"
			fill="url(#paint0_linear_1733_293438)"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12 3C12.5523 3 13 3.44772 13 4V5.83C13 6.38228 12.5523 6.83 12 6.83C11.4477 6.83 11 6.38228 11 5.83V4C11 3.44772 11.4477 3 12 3Z"
			fill="url(#paint1_linear_1733_293438)"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M3 12C3 11.4477 3.44772 11 4 11H5.83C6.38228 11 6.83 11.4477 6.83 12C6.83 12.5523 6.38228 13 5.83 13H4C3.44772 13 3 12.5523 3 12Z"
			fill="url(#paint2_linear_1733_293438)"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12 17.1699C12.5523 17.1699 13 17.6176 13 18.1699V19.9999C13 20.5522 12.5523 20.9999 12 20.9999C11.4477 20.9999 11 20.5522 11 19.9999V18.1699C11 17.6176 11.4477 17.1699 12 17.1699Z"
			fill="url(#paint3_linear_1733_293438)"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M14.2929 14.2929C14.6834 13.9024 15.3166 13.9024 15.7071 14.2929L19.7071 18.2929C20.0976 18.6834 20.0976 19.3166 19.7071 19.7071C19.3166 20.0976 18.6834 20.0976 18.2929 19.7071L14.2929 15.7071C13.9024 15.3166 13.9024 14.6834 14.2929 14.2929Z"
			fill={greyed ? greyColor : lightBlueColor}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M19.7071 4.29289C20.0976 4.68342 20.0976 5.31658 19.7071 5.70711L15.7071 9.70711C15.3166 10.0976 14.6834 10.0976 14.2929 9.70711C13.9024 9.31658 13.9024 8.68342 14.2929 8.29289L18.2929 4.29289C18.6834 3.90237 19.3166 3.90237 19.7071 4.29289Z"
			fill={greyed ? greyColor : lightBlueColor}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M4.29289 4.29289C4.68342 3.90237 5.31658 3.90237 5.70711 4.29289L9.70711 8.29289C10.0976 8.68342 10.0976 9.31658 9.70711 9.70711C9.31658 10.0976 8.68342 10.0976 8.29289 9.70711L4.29289 5.70711C3.90237 5.31658 3.90237 4.68342 4.29289 4.29289Z"
			fill={greyed ? greyColor : lightBlueColor}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M9.70711 14.2929C10.0976 14.6834 10.0976 15.3166 9.70711 15.7071L5.70711 19.7071C5.31658 20.0976 4.68342 20.0976 4.29289 19.7071C3.90237 19.3166 3.90237 18.6834 4.29289 18.2929L8.29289 14.2929C8.68342 13.9024 9.31658 13.9024 9.70711 14.2929Z"
			fill={greyed ? greyColor : lightBlueColor}
		/>
		<defs>
			<linearGradient
				id="paint0_linear_1733_293438"
				x1="20.9999"
				y1="12"
				x2="17.4999"
				y2="12"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0.270833" stopColor={greyed ? greyColor : lightBlueColor} />
				<stop offset="1" stopColor={greyed ? greyColor : blueColor} />
			</linearGradient>
			<linearGradient
				id="paint1_linear_1733_293438"
				x1="12.5"
				y1="3"
				x2="12.5"
				y2="6.5"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0.1875" stopColor={greyed ? greyColor : lightBlueColor} />
				<stop offset="1" stopColor={greyed ? greyColor : blueColor} />
			</linearGradient>
			<linearGradient
				id="paint2_linear_1733_293438"
				x1="6.82996"
				y1="12"
				x2="3.32996"
				y2="12"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor={greyed ? greyColor : blueColor} />
				<stop offset="0.791667" stopColor={greyed ? greyColor : lightBlueColor} />
			</linearGradient>
			<linearGradient
				id="paint3_linear_1733_293438"
				x1="12.5"
				y1="16.9999"
				x2="12.5"
				y2="20.9999"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor={greyed ? greyColor : blueColor} />
				<stop offset="0.802083" stopColor={greyed ? greyColor : lightBlueColor} />
			</linearGradient>
		</defs>
	</svg>
);

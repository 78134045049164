import { fg } from '@confluence/feature-gating';

import { getIsCreateIssueWithAiEnabledByUser } from './localStorageHelpers';
import { useAiIssueCreationEnablementState } from '../providers/IssueCreateSidePanelContextProvider';

export const useIsAIIssueCreationEnabled = () => {
	const aiIssueCreationEnablement = useAiIssueCreationEnablementState();
	const isAiEnabled = aiIssueCreationEnablement?.isEnabled ?? false;
	const isCreateIssueWithAiEnabledByUser = getIsCreateIssueWithAiEnabledByUser();

	if (fg('confluence_single_issue_create_ai_toggle')) {
		return isAiEnabled && isCreateIssueWithAiEnabledByUser;
	}

	return isAiEnabled;
};

import {
	NLPSearchResultFormat,
	type NLPSearchType,
	type KeyPhraseNodeType,
} from '@atlassian/search-ai';

import type { HighlightPhraseNodeType } from './useHighlightAcronyms';
import type { CuratedDefinitionType } from './gql/__types__/CuratedDefinitionQuery';

export const generatePageARI = (
	contentId: string,
	cloudID: string,
	contentType: string | null | undefined,
): string => {
	if (!contentType) {
		return '';
	}
	return `ari:cloud:confluence:${cloudID}:${contentType}/${contentId}`;
};

export const generateWorkspaceId = (activationId: string, cloudId: string): string =>
	`ari:cloud:confluence:${cloudId}:workspace/${activationId}`;

export const mapCuratedDefinitionToNLPSearchType = (
	curatedDefinition: CuratedDefinitionType,
): NLPSearchType => {
	const curatedDefinitionReference = curatedDefinition.confluenceEntity;

	return {
		nlpResult: curatedDefinition.definition,
		uniqueSources: curatedDefinitionReference
			? [
					{
						id: curatedDefinitionReference?.id || '',
						title: curatedDefinitionReference?.title || '',
						url: curatedDefinition.referenceUrl || '',
						type: curatedDefinitionReference?.type || '',
						lastModified: '',
						iconUrl: '',
						spaceName: curatedDefinitionReference?.space?.name || '',
						spaceUrl: null,
					},
				]
			: [],
		nlpResultEditor: curatedDefinition.editor?.name
			? {
					name: curatedDefinition.editor.name || '',
					id: curatedDefinition.editor.accountId || '',
				}
			: undefined,
		disclaimer: null,
		errorState: null,
		format: NLPSearchResultFormat.MARKDOWN,
	};
};

export const READING_AIDS_MAX_WORD_COUNT = 5;

export const getHighlightCategories = (nodes: HighlightPhraseNodeType[] | KeyPhraseNodeType[]) => {
	const categories = new Map<string, number>();
	try {
		nodes.forEach((node) => {
			const curCategoryCount = categories.get(node.category);
			if (!!curCategoryCount) {
				categories.set(node.category, curCategoryCount + 1);
			} else {
				categories.set(node.category, 1);
			}
		});
	} catch {
		return undefined;
	}
	return Object.fromEntries(categories);
};

/** @jsx jsx */
import type { FC, ReactNode } from 'react';
import { useIntl, defineMessages } from 'react-intl-next';
import { useLayoutEffect, useRef, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { keyframes, css, jsx } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import VisuallyHidden from '@atlaskit/visually-hidden';

import { getElementBounds } from '@confluence/dom-helpers';
import { useIsNav4Enabled } from '@confluence/nav4-enabled';

import { popupPortalContainerId } from './HighlightActionsProvider';

const popupAnimation = keyframes({
	'0%': {
		opacity: '0',
	},
	'100%': {
		opacity: '1',
	},
});

const popupPanelStyles = css({
	position: 'absolute',
	animation: `${popupAnimation} 100ms forwards linear`,
});

const popupTransition = css({
	transition: 'top 100ms ease-out, left 100ms ease-out',
});

const buttonContainerStyle = css({
	transform: `translate(calc(-50% - 5px), calc(-100% - 20px))`,
	boxShadow: token('elevation.shadow.overlay'),
	borderRadius: '3px',
	position: 'absolute',
	top: '0',
	backgroundColor: token('elevation.surface.overlay'),
	display: 'flex',
});

const i18n = defineMessages({
	toolbarAlert: {
		id: 'highlight-actions.toolbar.alert',
		defaultMessage: 'Floating toolbar is available. Press tab to access it',
		description:
			'Alert to announce to screenreader users how to access the floating toolbar with the keyboard',
	},
});

type PopupProps = {
	children: ReactNode;
	top: number;
	left: number;
	needTransition?: boolean;
	onMouseEnter?: () => void;
	onMouseLeave?: () => void;
};

export const Popup: FC<PopupProps> = ({
	children,
	top,
	left,
	needTransition = true,
	onMouseLeave,
	onMouseEnter,
}) => {
	const isNav4Enabled = useIsNav4Enabled();

	const WINDOW_PADDING = 20;
	const popupRef = useRef(null);
	const [adjustedLeft, setAdjustedLeft] = useState(left);
	const [adjustedLeftOrigin, setAdjustedLeftOrigin] = useState(left);
	const { formatMessage } = useIntl();

	useLayoutEffect(() => {
		if (!popupRef.current) return;

		const { left: elementLeft, right } = getElementBounds(popupRef.current!);
		const windowWidth = window.innerWidth;

		let containerBounds = { left: 0, right: windowWidth }; // In Nav3, the window is the container
		if (isNav4Enabled) {
			const nav4ContentContainer = document.getElementById('AkMainContent');
			containerBounds = !!nav4ContentContainer
				? getElementBounds(nav4ContentContainer)
				: containerBounds;
		}

		// WS-1703 - If the popup menu is off either side of the screen or outside of the scrollable content area in Nav4,
		// set it so it's inside the WINDOW_PADDING
		if (elementLeft < containerBounds.left) {
			setAdjustedLeft(left + (containerBounds.left - elementLeft) + WINDOW_PADDING);
		} else if (right > containerBounds.right) {
			setAdjustedLeft(left - (right - containerBounds.right) - WINDOW_PADDING);
		} else {
			setAdjustedLeft(left);
		}
		setAdjustedLeftOrigin(left);
	}, [isNav4Enabled, left]);

	const portalContainer = document.querySelector(`#${popupPortalContainerId}`);
	const mouseMoveHandlers = onMouseEnter && onMouseLeave && { onMouseEnter, onMouseLeave };

	useEffect(() => {
		const popupPortal = document.getElementById(popupPortalContainerId);
		if (!popupPortal) return;

		const screenreaderCb = (_mutationsList, observer) => {
			//These accessibility attributes are added dynamically here rather than directly on the VisuallyHidden element because it did not get read by the NVDA screenreader properly on Firefox. This is due to the screenreader sometimes only reading content when there has been a dynamic change to an element
			const accessibleDiv = document.getElementById('toolbar-alert');
			if (accessibleDiv instanceof HTMLElement) {
				accessibleDiv?.setAttribute('role', 'alert');
				accessibleDiv?.setAttribute('aria-live', 'polite');
				observer.disconnect();
			}
		};

		const observer = new MutationObserver(screenreaderCb);
		observer.observe(popupPortal, { childList: true, subtree: true });

		return () => {
			observer.disconnect();
		};
	}, []);

	// Only use the adjustedLeft if it was calculated relative to the current left prop
	const popupLeft = adjustedLeftOrigin === left ? adjustedLeft : left;

	return portalContainer
		? ReactDOM.createPortal(
				<div
					style={{ top }}
					{...mouseMoveHandlers}
					css={[popupPanelStyles, needTransition && popupTransition]}
				>
					<VisuallyHidden id="toolbar-alert">{formatMessage(i18n.toolbarAlert)}</VisuallyHidden>
					<div
						css={buttonContainerStyle}
						ref={popupRef}
						style={{ left: popupLeft ? `${popupLeft}px` : '0px' }}
						data-testid="highlightActionsPopup"
					>
						{children}
					</div>
				</div>,
				portalContainer,
			)
		: null;
};
